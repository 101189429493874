import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        contactUsResponse: []
    },
    getters: {
        contactUsResponse: (state) => state.contactUsResponse
    },
    mutations: {
        SET_CONTACT_US_RESPONSE(state, data) {
            state.contactUsResponse = data;
        }
    },
    actions: {
        async contactUs({ commit }, data) {
            NProgress.start();
            await axios.post("contact-us", data).then((response) => {
                NProgress.done();
                commit("SET_CONTACT_US_RESPONSE", response.data);
            },
                (error) => {
                    NProgress.done();
                    commit("SET_CONTACT_US_RESPONSE", error.response.data);
                }
            );
        },
    },
};
