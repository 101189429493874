import axios from "../../axios";
import NProgress from "nprogress";

export default {
  state: {
    profileData: [],
    udpateProfileResponse: [],
    addAddressResponse: [],
    updateAddressResponse: [],
    deleteAddressResponse: [],
    changePasswordResponse: [],
  },
  getters: {
    profileData: (state) => state.profileData,
    udpateProfileResponse: (state) => state.udpateProfileResponse,
    addAddressResponse: (state) => state.addAddressResponse,
    updateAddressResponse: (state) => state.updateAddressResponse,
    deleteAddressResponse: (state) => state.deleteAddressResponse,
    changePasswordResponse: (state) => state.changePasswordResponse,
  },
  mutations: {
    SET_PROFILE_DATA(state, data) {
      state.profileData = data;
    },
    SET_UPDATE_PROFILE_RESPONSE(state, data) {
      state.udpateProfileResponse = data;
    },
    SET_ADD_ADDRESS_RESPONSE(state, data) {
      state.addAddressResponse = data;
    },
    SET_UPDATE_ADDRESS_RESPONSE(state, data) {
      state.updateAddressResponse = data;
    },
    SET_DELETE_ADDRESS_RESPONSE(state, data) {
      state.deleteAddressResponse = data;
    },
    SET_CHANGE_PASSWORD_RESPONSE(state, data) {
      state.changePasswordResponse = data;
    },
  },
  actions: {
    async getProfileInfo({ commit }) {
      await axios.get("profile").then(
        (response) => {
          if (response.data.code == 401) {
            this.dispatch("logout");
          } else {
            this.dispatch("updateCartItemCount", response.data?.response?.cart);
            commit("SET_PROFILE_DATA", response.data);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.dispatch("logout");
          } else {
            commit("SET_PROFILE_DATA", error.response.data);
          }
        }
      );
    },

    async updateProfileInfo({ commit }, data) {
      NProgress.start();
      await axios
        .put(`profile`, {
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          civil_id: data.civil_id_number,
          email: data.email,
        })
        .then(
          (response) => {
            NProgress.done();
            if (response.data.code == 401) {
              this.dispatch("logout");
            } else {
              this.dispatch(
                "updateCartItemCount",
                response.data?.response?.cart
              );
              commit("SET_UPDATE_PROFILE_RESPONSE", response.data);
            }
          },
          (error) => {
            NProgress.done();
            if (error.response.status == 401) {
              this.dispatch("logout");
            } else {
              commit("SET_UPDATE_PROFILE_RESPONSE", error.response.data);
            }
          }
        );
    },

    async addNewAddress({ commit }, data) {
      NProgress.start();
      await axios.post("address", data).then(
        (response) => {
          NProgress.done();
          if (response.data.code == 401) {
            this.dispatch("logout");
          } else {
            this.dispatch("updateCartItemCount", response.data?.response?.cart);
            commit("SET_ADD_ADDRESS_RESPONSE", response.data);
          }
        },
        (error) => {
          NProgress.done();
          if (error.response.status == 401) {
            this.dispatch("logout");
          } else {
            commit("SET_ADD_ADDRESS_RESPONSE", error.response.data);
          }
        }
      );
    },

    async updateCustomerAddress({ commit }, data) {
      NProgress.start();
      await axios
        .put(`address/${data.id}`, {
          location: data.location,
          area_id: data.area_id,
          street: data.street,
          block: data.block,
          avenue: data.avenue,
          house_building: data.house_building,
          floor: data.floor,
          apartment: data.apartment,
          special_direction: data.special_direction,
          gov_id: data.gov_id,
        })
        .then(
          (response) => {
            NProgress.done();
            if (response.data.code == 401) {
              this.dispatch("logout");
            } else {
              this.dispatch(
                "updateCartItemCount",
                response.data?.response?.cart
              );
              commit("SET_UPDATE_ADDRESS_RESPONSE", response.data);
            }
          },
          (error) => {
            NProgress.done();
            if (error.response.status == 401) {
              this.dispatch("logout");
            } else {
              commit("SET_UPDATE_ADDRESS_RESPONSE", error.response.data);
            }
          }
        );
    },

    async deleteCustomerAddress({ commit }, id) {
      NProgress.start();
      await axios.delete(`address/${id}`).then(
        (res) => {
          NProgress.done();
          if (res.data.code == 401) {
            this.dispatch("logout");
          } else {
            this.dispatch("updateCartItemCount", res.data?.response?.cart);
            commit("SET_DELETE_ADDRESS_RESPONSE", res.data);
          }
        },
        (error) => {
          NProgress.done();
          if (error.response.status == 401) {
            this.dispatch("logout");
          } else {
            commit("SET_DELETE_ADDRESS_RESPONSE", error.response.data);
          }
        }
      );
    },

    async changePassword({ commit }, data) {
      NProgress.start();
      await axios
        .put(`password`, {
          old_password: data.old_password,
          new_password: data.new_password,
          confirm_password: data.confirm_password,
        })
        .then(
          (response) => {
            NProgress.done();
            if (response.data.code == 401) {
              this.dispatch("logout");
            } else {
              this.dispatch(
                "updateCartItemCount",
                response.data?.response?.cart
              );
              commit("SET_CHANGE_PASSWORD_RESPONSE", response.data);
            }
          },
          (error) => {
            NProgress.done();
            if (error.response.status == 401) {
              this.dispatch("logout");
            } else {
              commit("SET_CHANGE_PASSWORD_RESPONSE", error.response.data);
            }
          }
        );
    },
  },
};
