<template>
    <!-- <nav class="panel-menu mobile-main-menu">
        <ul>
            <li>
                <a href="javascript:void(0);" class="mob-sidebar-about-us">{{
                    $t("header.aboutUs")
                }}</a>
            </li>
            <li>
                <a href="javascript:void(0);" class="package-tiles cursor-pointer">{{
                    $t("header.packages")
                }}</a>
            </li>
            <li v-show="isLoggedIn">
                <a href="#"><span>{{ $t("header.myAccount") }}</span></a>
                <ul>
                    <li><a href="javascript:void(0);" class="mob-sidebar-profile">{{ $t("header.profile") }}</a></li>
                    <li><a href="javascript:void(0);" class="mob-sidebar-orders">{{ $t("header.orders") }}</a></li>
                    <li><a href="javascript:void(0);" class="mob-sidebar-appointments">{{ $t("header.appointments") }}</a></li>
                </ul>
            </li>
            <li v-show="isLoggedIn">
                <a class="mobile-logout-class cursor-pointer">{{ $t("header.logout") }}</a>
            </li>
            <li v-show="!isLoggedIn">
                <a href="javascript:void(0);" class="mob-sidebar-login">{{
                    $t("header.login")
                }}</a>
            </li>

            <li>
                <a href="javascript:void(0);" class="mob-sidebar-contact">{{ $t("header.contactUs") }}</a>
            </li>

            <li>
                <a class="mobile-locale-class" href="#">{{
                    $t("header.localeLang")
                }}</a>
            </li>

            <li>
                <a href="javascript:void(0);" class="book-appointment mt-3 text-primary font-weight-bold font-15 text-underline">{{ $t("dietitianAppointment.bookAnAppointment") }}</a>
            </li>
        </ul>
    </nav> -->

    <SideMenu ref="sideNav" />
    <div class="pt-mobile-header">
        <div class="container-fluid">
            <div class="pt-header-row">
                <!-- mobile menu toggle -->
                <div class="pt-mobile-parent-menu" @click="$refs.sideNav.openNav()">
                    <div class="pt-menu-toggle">
                        <svg width="24" height="24" viewBox="0 0 24 24">
                            <use
                                xlink:href="#icon-mobile-menu-toggle"
                            ></use>
                        </svg>
                    </div>
                </div>
                <!-- /mobile menu toggle -->
                <div class="pt-logo-container">
                    <!-- mobile logo -->
                    <div
                        class="pt-logo"
                        itemscope
                        itemtype="http://schema.org/Organization"
                    >
                        <a href="/" itemprop="url">
                            <img
                                :src="mobileLogoURL"
                                alt="Platinum Fit"
                                width="75"
                                height="75"
                            />
                        </a>
                    </div>
                    <!-- /mobile logo -->
                </div>
                <!-- cart -->
                <div class="pt-mobile-parent-search pt-parent-box"></div>
                <div class="pt-mobile-parent-cart pt-parent-box"></div>
                <!-- /cart -->
            </div>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'
import SideMenu from "./SideMenu.vue";

export default {
    components: {
        SideMenu,
    },
    methods: {
        clickedLogout() {
            this.$store.dispatch('logout');
        },
        
        redirectToAboutUs() {
            this.$router.push("/about-us")
        },

        redirectToProfile() {
            this.$router.push("/profile")
        },

        redirectToOrders() {
            this.$router.push("/orders")
        },

        redirectToAppointments() {
            this.$router.push("/appointments")
        },

        redirectToLogin() {
            this.$router.push("/login")
        },

        redirectToContact() {
            this.$router.push("/contact-us")
        },

        redirectToPackgesSection() {
            this.$router.push("/#packages")
        },

        redirectToBookingAppointment() {
            this.$router.push({ name: "DietitianAppointment" });
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: (state) => state.config.isAuthenticated,
        }),
        mobileLogoURL() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('header_logo_mobile')?.value
        }
    },
    watch: {
        isLoggedIn(newVal, OldVal) {
            if (newVal != OldVal) this.$forceUpdate()
        },
        siteContentResponse(data) {
            localStorage.setItem("siteContentData", JSON.stringify(data))
            this.$root.setSiteContentLoaded(true);
        }
    },
    mounted() {
        window.mobileHeaderComponent = this;
    },
};
</script>
