<template>
  <header-view />
  <router-view :key="$route.fullPath"></router-view> 
  <footer-view v-if="showFooterView"/>

  <div v-if="false" @click ="$router.push({ name: 'DietitianAppointment' })" class="d-none d-lg-flex book-appointment-btn float-desktop row m-0 cursor-pointer" :class="$i18n.locale == 'ar' ? 'float-desktop-ar' : ''">
    <span class="float-icon text-white material-icons">calendar_month</span>
    <p class="font-weight-bold mt-0 my-auto" :class="$i18n.locale == 'ar' ? 'pl-2' : 'pr-2'">{{ $t("dietitianAppointment.bookAnAppointment") }}</p> 
  </div>
  
  <svg-view />
</template>

<script>
import HeaderView from "@/components/Header.vue";
import FooterView from "@/components/Footer.vue";
import SvgView from "@/components/SVG.vue";
import * as sessionManager from '@/common_services/session-manager.js';


export default {
  components: {
    HeaderView,
    FooterView,
    SvgView,
  },
  data() {
    return {
      showFooterView: false,
      isSiteContentLoaded: false
    }
  },
  computed: {
    isLoggedIn() {
          return sessionManager.isLoggedIn();
      },
  },
  methods: {
    logMsg(msg) {
      console.log(msg);
    },

    showValidationError(fieldKey, inputName) {
			let errorMsg = fieldKey.$errors[0].$message;
			if (errorMsg.includes(inputName)) errorMsg = errorMsg.replace(inputName, this.$t("inputKeys."+inputName))
			return errorMsg;
		},

    getSiteContentDataForKey(contentKey) {
      if (localStorage.getItem("siteContentData") != null) {
        const siteContentData = JSON.parse(localStorage.getItem("siteContentData"));
        if (siteContentData == null) return null
        const siteContentKeyData = siteContentData.find((contentData) => contentData.key === contentKey)
        siteContentKeyData.value = this.$i18n.locale == 'ar' ? siteContentKeyData?.value_ar : siteContentKeyData?.value_en
        return siteContentKeyData.value != null ? siteContentKeyData : null
      }
      return null
		},

    /**
     * use the below code to callt this method
     * this.$root.showCustomAlert("success", data.message);
     */
    showCustomAlert(alertType, message) {
      this.$swal({
        type: alertType,
        text: message,
        customClass: {
          popup: "custom-swal-popup",
          confirmButton: "custom-confirm-button",
        },
      });
    },
    showAuthAlert(title, message, redirectURL = '') {
      let imagePath = require("@/assets/images/icons/alert.png");
      this.$swal({
        imageUrl: imagePath,
        customClass: {
          icon: "no-border",
          confirmButton: "custom-confirm-button",
          popup: "custom-swal-popup",
        },
        title: title,
        text: message,
        confirmButtonText: this.$t("common.loginRegister"),
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push({ path: '/login', query: { redirect: redirectURL }})
        }
      });
    },

    updateFooterViewStatus(status) {
      this.showFooterView = status;
    },
    setSiteContentLoaded(status) {
      this.isSiteContentLoaded = status;
    }
  },
  created() {
    window.addEventListener('load', () => {
      let externalScript = document.createElement('script')
      externalScript.setAttribute('src', '/assets/js/bundle.js')
      externalScript.async = true
      document.head.appendChild(externalScript)
      localStorage.setItem("isLangChanged", "false")
    })    
  }
};
</script>

<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.float-desktop{
	position:absolute;
	width:auto;
	height:35px;
	right:120px;
	top:24px;
	background-color:#f36122;
	color:#fff;
	text-align:center;
  font-size: 13px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  z-index: 9998;

  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.float-desktop-ar{
    left: 120px;
    right: unset;
}

.float-mobile{
	position:fixed;
	width:45px;
	height:45px;
	right:60px;
	top:12px;
	background-color:#fff;
	color:#353545;
	text-align:center;
  font-size: 15px;
  white-space: nowrap;
  text-align: center;
  overflow: hidden;
  z-index: 9998;
}

.float-mobile:focus {
  width: auto
}

.float-icon{
  padding: 6px 8px;

}

.float-text{
  padding-right: 15px;
  color: #fff;
}

.w-auto {
  width: auto !important;
}
.new-color{
  color: #f36122 !important;
}
.v3dp__input_wrapper .input-calendar{
    border-radius: 10px;
}
</style>
