import axios from '../../axios'

export default {
    state: {
        packageCategory: {},
        packageCategoryImages: [],
        dislikesAllergies: [],
        mealsData: [],
        toastError: [],
        mealSelectionToast: []
    },
    getters: {
        packageCategory: (state) => state.packageCategory,
        packageCategoryImages: (state) => state.packageCategoryImages,
        dislikesAllergies: (state) => state.dislikesAllergies,
        mealsData: (state) => state.mealsData,
        toastError: (state) => state.toastError,
        mealSelectionToast: (state) => state.mealSelectionToast,
    },
    mutations: {
        SET_PACKAGE_DETAILS(state, data) {
            state.packageCategory = data.package_category;
            state.packageCategoryImages = data.package_category_images;
        },
        SET_DISLIKES_ALLERGIES(state, data) {
            state.dislikesAllergies = data;
        },
        SET_MEALS(state, data) {
            state.mealsData = data;
        },
        SET_TOAST(state, data) {
            state.toastError = data;
        },
        SET_MEAL_SELECTION_TOAST(state, data) {
            state.mealSelectionToast = data;
        },
    },
    actions: {
        async getPackageDetails({commit}, id) {
            await axios.get(`package-categories/${id}`).then(
                (res) => {
                    this.dispatch('updateCartItemCount', res.data?.response?.cart);
                    commit("SET_PACKAGE_DETAILS", (res.data.status) ? res.data.response : {});
                },
                (error) => {
                    commit("SET_TOAST", error.response.data);
                }
            );
        },

        async getDislikesAllergies({commit}, id) {
            await axios.get(`dislikes-allergies/${id}`).then(
                (res) => {
                    this.dispatch('updateCartItemCount', res.data?.response?.cart);
                    commit("SET_DISLIKES_ALLERGIES", (res.data.status) ? res.data.response : {});
                },
                (error) => {
                    commit("SET_TOAST", error.response.data);
                }
            );
        },

        async storeDislikesAllergies({commit}, data) {
            await axios.post(`dislikes-allergies`, data).then(
                (res) => {
                    this.dispatch('updateCartItemCount', res.data?.response?.cart);
                },
                (error) => {
                    commit("SET_TOAST", error.response.data);
                }
            );
        },

        async getMeals({commit}, id) {
            await axios.get(`meals?cart_item_id=${id}`).then(
                (res) => {
                    this.dispatch('updateCartItemCount', res.data?.response?.cart);
                    commit("SET_MEALS", (res.data.status) ? res.data.response : {});
                },
                (error) => {
                    commit("SET_TOAST", error.response.data);
                }
            );
        },

        async storeMealSelection({commit}, data) {
            await axios.post(`meal-selection`, data).then(
                (res) => {
                    this.dispatch('updateCartItemCount', res.data?.response?.cart);
                    commit("SET_MEAL_SELECTION_TOAST", res.data)
                },
                (error) => {
                    commit("SET_TOAST", error.response.data);
                }
            );
        },

    }
}