import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        subscriptions: [],
        subscription: {},
        toastError: []
    },
    getters: {
        subscriptions: (state) => state.subscriptions,
        subscription: (state) => state.subscription,
        toastError: (state) => state.toastError,
    },
    mutations: {
        SET_SUBSCRIPTIONS(state, data) {
            state.subscriptions = data.subscriptions;
        },
        SET_SUBSCRIPTION(state, data) {
            state.subscription = data.subscription_details;
        },
        SET_TOAST(state, data) {
            state.toastError = data;
        },
    },
    actions: {
        async getSubscriptions({commit}) {
            NProgress.start();
            await axios.get(`subscriptions`).then(
                (res) => {
                    NProgress.done();
                    if (res.data.code == 401) {
                        this.dispatch("logout");
                    } else {
                        this.dispatch('updateCartItemCount', res.data?.response?.cart);
                        commit("SET_SUBSCRIPTIONS", (res.data.status) ? res.data.response : {});
                    }
                },
                (error) => {
                    NProgress.done();
                    if (error.response.status == 401) {
                        this.dispatch("logout");
                    } else {
                        commit("SET_TOAST", error.response.data);
                    }
                }
            );
        },

        async getSubscriptionDetails({commit}, id) {
            NProgress.start();
            await axios.get(`subscriptions/${id}`).then(
                (res) => {
                    NProgress.done();
                    if (res.data.code == 401) {
                        this.dispatch("logout");
                    } else {
                        this.dispatch('updateCartItemCount', res.data?.response?.cart);
                        commit("SET_SUBSCRIPTION", (res.data.status) ? res.data.response : {});
                    }
                },
                (error) => {
                    NProgress.done();
                    if (error.response.status == 401) {
                        this.dispatch("logout");
                    } else {
                        commit("SET_TOAST", error.response.data);
                    }
                }
            );
        },

    }
}