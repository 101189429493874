import axios from "../../axios";
import NProgress from "nprogress";

export default {
  state: {
    customerData: [],
    registrationResponse: [],
    forgotPasswordResponse: [],
    updatePasswordResponse: [],
    registerFormData: [],
    quickSignupResponse: [],
  },
  getters: {
    customerData: (state) => state.customerData,
    registrationResponse: (state) => state.registrationResponse,
    forgotPasswordResponse: (state) => state.forgotPasswordResponse,
    updatePasswordResponse: (state) => state.updatePasswordResponse,
    registerFormData: (state) => state.registerFormData,
    quickSignupResponse: (state) => state.quickSignupResponse,
  },
  mutations: {
    SET_AUTH_RESPONSE(state, data) {
      state.customerData = data;
    },
    SET_REGISTRATION_RESPONSE(state, data) {
      state.registrationResponse = data;
    },
    SET_FORGOT_PASSWORD_RESPONSE(state, data) {
      state.forgotPasswordResponse = data;
    },
    SET_UPDATE_PASSWORD_RESPONSE(state, data) {
      state.updatePasswordResponse = data;
    },
    SET_REGISTER_FORM_DATA(state, data) {
      state.registerFormData = data;
    },
    SET_QUICK_SIGN_UP_RESPONSE(state, data) {
      state.quickSignupResponse = data;
    },
  },
  actions: {
    async login({ commit }, data) {
      NProgress.start();
      await axios.post("login", data).then(
        (response) => {
          NProgress.done();
          if (response.data.status) {
            this.dispatch("updateCartItemCount", response.data?.response?.cart);
            commit("SET_AUTH_RESPONSE", response.data);
          }
        },
        (error) => {
          NProgress.done();
          commit("SET_AUTH_RESPONSE", error.response.data);

        }
      );
    },

    async logout({ commit }) {
      await axios.post("logout").then((res) => {
        NProgress.done();
        this.dispatch("updateCartItemCount", null);
        commit("SET_AUTH_RESPONSE", res.status ? [] : []);

      });
      localStorage.removeItem("customerInfo");
      window.location.href = "/login";
    },

    async registerNewUSer({ commit }, data) {
      NProgress.start();
      await axios.post("register", data).then(
        (response) => {
          NProgress.done();
          if (response.data.status) {
            this.dispatch("updateCartItemCount", response.data?.response?.cart);
            localStorage.setItem(
              "customerInfo",
              JSON.stringify(response.data.response)
            );
            commit("SET_REGISTRATION_RESPONSE", response.data);
          } else {
            commit("SET_REGISTRATION_RESPONSE", []);
          }
        },
        (error) => {
          NProgress.done();
          commit("SET_REGISTRATION_RESPONSE", error.response.data);
        }
      );
    },

    async forgotPassword({ commit }, data) {
      NProgress.start();
      await axios.post("reset-password-token", data).then(
        (response) => {
          NProgress.done();
          commit("SET_FORGOT_PASSWORD_RESPONSE", response.data);
        },
        (error) => {
          NProgress.done();
          commit("SET_FORGOT_PASSWORD_RESPONSE", error.response.data);
        }
      );
    },

    async updadePassword({ commit }, data) {
      NProgress.start();
      await axios.post("reset-password", data).then(
        (response) => {
          NProgress.done();
          commit("SET_UPDATE_PASSWORD_RESPONSE", response.data);
        },
        (error) => {
          NProgress.done();
          commit("SET_UPDATE_PASSWORD_RESPONSE", error.response.data);
        }
      );
    },

    async gerRegisterFormDetails({ commit }) {
      await axios.get("register").then(
        (response) => {
          NProgress.done();
          commit("SET_REGISTER_FORM_DATA", response.data);
        },
        (error) => {
          NProgress.done();
          commit("SET_REGISTER_FORM_DATA", error.response.data);
        }
      );
    },

    async quickSignup({ commit }, data) {
      NProgress.start();
      await axios.post("quick-signup", data).then(
        (response) => {
          NProgress.done();
          if (response.data.status) {
            localStorage.removeItem("customerInfo", null);
            this.dispatch("updateCartItemCount", response.data?.response?.cart);
            localStorage.setItem(
              "customerInfo",
              JSON.stringify(response.data.response)
            );
          }
          commit("SET_QUICK_SIGN_UP_RESPONSE", response.data);
        },
        (error) => {
          NProgress.done();
          commit("SET_QUICK_SIGN_UP_RESPONSE", error.response.data);
        }
      );
    },
  },
};
