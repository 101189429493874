import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        packageCategories: [],
        packageCategoriesToast: [],
        siteContentResponse: []
    },
    getters: {
        packageCategories: (state) => state.packageCategories,
        packageCategoriesToast: (state) => state.packageCategoriesToast,
        siteContentResponse: (state) => state.siteContentResponse,
    },
    mutations: {
        SET_PACKAGE_CATEGORIES(state, data) {
            state.packageCategories = data;
        },
        SET_SITE_CONTENT_RESPONSE(state, data) {
            state.siteContentResponse = data;
        },
        SET_TOAST(state, data) {
            state.packageCategoriesToast = data;
        },
    },
    actions: {

        async getHomeScreenData({commit}) {
            NProgress.start();
            await axios.get(`home`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    } else if (res.data.status) {
                        this.dispatch('updateCartItemCount', res.data?.response?.cart);
                        commit("SET_PACKAGE_CATEGORIES", res.data.response);
                        commit("SET_SITE_CONTENT_RESPONSE", res.data.response.content);
                    } else {
                        commit("SET_PACKAGE_CATEGORIES", []);
                        commit("SET_TOAST", res.data);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_TOAST", error.response);
                }
            );
        },

    }
}