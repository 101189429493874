<template>
    <header id="pt-header">

        <!-- pt-mobile menu -->
        <MobileHeader />
        
        <!-- pt-desktop-header -->
        <div class="pt-desktop-header">
            <div class="container-fluid">
                <div class="headinfo-box form-inline justify-content-between">

                    <div>
                        <div class="pt-desctop-parent-wishlist pt-parent-box">
                            <div class="pt-wishlist pt-dropdown-obj js-dropdown" data-ajax="ajax-content/ajax_dropdown-cart.html">
                                <!-- <a class="p-0" href="#" @click="clickedChangeLanguage()">
                                    <span>
                                        <img src="@/assets/images/icons/language.png" alt="Platinum Fits" height="75" />
                                    </span>
                                </a> -->
                                <div class="lang-container" @click="clickedChangeLanguage()" role="button">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M12 22q-2.05 0-3.875-.788t-3.187-2.15t-2.15-3.187T2 12q0-2.075.788-3.887t2.15-3.175t3.187-2.15T12 2q2.075 0 3.888.788t3.174 2.15t2.15 3.175T22 12q0 2.05-.788 3.875t-2.15 3.188t-3.175 2.15T12 22m0-2.05q.65-.9 1.125-1.875T13.9 16h-3.8q.3 1.1.775 2.075T12 19.95m-2.6-.4q-.45-.825-.787-1.713T8.05 16H5.1q.725 1.25 1.813 2.175T9.4 19.55m5.2 0q1.4-.45 2.488-1.375T18.9 16h-2.95q-.225.95-.562 1.838T14.6 19.55M4.25 14h3.4q-.075-.5-.112-.987T7.5 12t.038-1.012T7.65 10h-3.4q-.125.5-.187.988T4 12t.063 1.013t.187.987m5.4 0h4.7q.075-.5.113-.987T14.5 12t-.038-1.012T14.35 10h-4.7q-.075.5-.112.988T9.5 12t.038 1.013t.112.987m6.7 0h3.4q.125-.5.188-.987T20 12t-.062-1.012T19.75 10h-3.4q.075.5.113.988T16.5 12t-.038 1.013t-.112.987m-.4-6h2.95q-.725-1.25-1.812-2.175T14.6 4.45q.45.825.788 1.713T15.95 8M10.1 8h3.8q-.3-1.1-.775-2.075T12 4.05q-.65.9-1.125 1.875T10.1 8m-5 0h2.95q.225-.95.563-1.838T9.4 4.45Q8 4.9 6.912 5.825T5.1 8"/></svg>
                                        <span v-if="$i18n.locale == 'en'">عربي</span>
                                        <span v-else>Enlglish</span>
                                    
                                </div>
                                <div class="pt-dropdown-menu"></div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-desctop-parent-menu">
                        <div class="pt-desctop-menu text-center">
                            <nav>
                                <ul class="">
                                    <li class="dropdown">
                                        <router-link to="/about-us">
											<span>{{ $t("header.aboutUs") }}</span>
										</router-link>
                                    </li>
 
                                    <li class="dropdown cursor-pointer">
                                        <a class="package-tiles">
                                            <span>{{ $t("header.packages") }}</span>
										</a>
                                    </li>
                                    <li class="dropdown mx-5">
                                        <div class="navinfo">
                                            <div class="pt-logo" itemscope itemtype="http://schema.org/Organization">
                                                <router-link to="/">
                                                    <div v-if="desktopLogoURL">
                                                        <img
                                                        :src="desktopLogoURL"
                                                        alt="Platinum Fit"
                                                        width="100px"
                                                        style="width: 100px"
                                                        />
                                                    </div>
                                                    
                                                </router-link>
                                            </div>
                                        </div>
                                    </li>

                                    <li v-show="!isLoggedIn" class="dropdown cursor-pointer">
                                        <router-link to="/login"><span>{{ $t("header.login") }}</span></router-link>
                                    </li>

                                    <li class="dropdown pt-megamenu-col-01" v-show="isLoggedIn">
                                        <a href="#">
											<span>{{ $t("header.myAccount") }}</span>
										</a>
                                        <div class="dropdown-menu">
                                            <div class="row pt-col-list">
                                                <div class="col">
                                                    <ul class="pt-megamenu-submenu text-left">
                                                        <li>
                                                            <router-link to="/profile">{{ $t("header.profile") }}</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/orders" >{{ $t("header.orders") }}</router-link>
                                                        </li>
                                                        <li>
                                                            <router-link to="/appointments" >{{ $t("header.appointments") }}</router-link>
                                                        </li>
                                                        <li>
                                                            <a @click.stop="clickedLogout()" class="cursor-pointer">{{ $t("header.logout") }}</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </li>

                                    <li class="dropdown">
                                        <router-link to="/contact-us">
											<span>{{ $t("header.contactUs") }}</span>
										</router-link>
                                    </li>

                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div v-if="false" class="d-lg-none">
                        <div class="pt-desctop-parent-search pt-parent-box">
                            <div class="pt-search pt-dropdown-obj js-dropdown" data-ajax="ajax-content/ajax_dropdown-cart.html">
                                <router-link to="/dietitian-appointment" class="p-0">
                                    <span class="material-icons font-33">calendar_month</span>
                                </router-link>
                                <div class="pt-dropdown-menu"></div>
                                
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="pt-desctop-parent-cart pt-parent-box">
                            <div class="pt-cart pt-dropdown-obj js-dropdown" data-ajax="ajax-content/ajax_dropdown-cart.html">
                                <a href="/cart" class="pt-dropdown-toggle" data-tooltip="Cart" data-tposition="bottom">
                                    <svg width="24" height="24" viewBox="0 0 24 24">
                                        <use xlink:href="#icon-cart_1"></use>
                                    </svg>
                                    <span v-if="showCartCount" class="pt-badge bg-primary en-text">{{ cartCount }}</span>
                                </a>
                                <div class="pt-dropdown-menu"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- stuck nav -->
        <div class="pt-stuck-nav">
            <div class="container-fluid">
                <div class="pt-header-row">
                    <div>
                        <div class="pt-stuck-parent-wishlist pt-parent-box"></div>
                    </div>
                    <div class="pt-stuck-parent-menu"></div>
                    <div class="pt-logo-container">
                        <!-- mobile logo -->
                        <div
                            class="pt-logo pt-logo-alignment"
                            itemscope
                            itemtype="http://schema.org/Organization"
                        >
                            <a href="/" itemprop="url">
                                <img
                                    :src="mobileLogoURL"
                                    alt="Platinum FitS"
                                    width="75"
                                    height="75"
                                />
                            </a>
                        </div>
                        <!-- /mobile logo -->
                    </div>
                    <div>
                        <div class="pt-stuck-parent-search pt-parent-box d-lg-none"></div>
                        <div class="pt-stuck-parent-cart pt-parent-box"></div>
                    </div>
                </div>
            </div>
        </div>

        <language-change-confirmation-modal ref="languageChangeConfirmationModal" @confirm="changeLanguage"></language-change-confirmation-modal>
    </header>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import MobileHeader from "./MobileHeader.vue";
import LanguageChangeConfirmationModal from "./languageChangeConfirmationModal.vue";

export default {
    data() {
        return {
            showCartCount: false,
        };
    },
    components: {
        MobileHeader,
        LanguageChangeConfirmationModal
    },
    computed: {
        ...mapGetters(["cartItemCount", "siteContentResponse"]),
        ...mapState({
            currentLang: (state) => state.config.currentLang,
            isLoggedIn: (state) => state.config.isAuthenticated,

        }),
        cartCount() {
            return this.cartItemCount > 0 ? this.cartItemCount : 0;
        },
        desktopLogoURL() { 
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }
            return this.$root.getSiteContentDataForKey('header_logo_desktop')?.value
        },
        mobileLogoURL() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }
            return this.$root.getSiteContentDataForKey('header_logo_mobile')?.value
        }
    },
    methods: {
        ...mapActions(["updateCartItemCount"]),
        loadArabicSite() {
            localStorage.setItem("currentLang", "ar");
            this.$i18n.locale = "ar";
            this.updateLanguageClassInBody("ar");
            this.$store.commit("config/setCurrentLang", "ar")
            require("@/assets/fonts/GE_Flow/ge-flow.css");
            this.appedRTLStyleSheetToHead();
        },

        loadEnglishSite() {
            localStorage.setItem("currentLang", "en");
            this.$i18n.locale = "en";
            this.updateLanguageClassInBody("en");
            this.$store.commit("config/setCurrentLang", "en")
            const element = document.getElementById("rtlCssFile");
            if (element) element.remove();
        },

        updateLanguageClassInBody(lang) {
            if (lang == "ar") {
                document.querySelector("body").classList.remove("ltr");
                document.querySelector("body").classList.add("rtl");
            } else {
                document.querySelector("body").classList.remove("rtl");
                document.querySelector("body").classList.add("ltr");
            }
        },

        appedRTLStyleSheetToHead() {
            var element = document.createElement("link");
            element.setAttribute("rel", "stylesheet");
            element.setAttribute("type", "text/css");
            element.setAttribute("id", "rtlCssFile");
            element.setAttribute("href", '../assets/css/rtl.css');
            document.head.appendChild(element);
        },

        clickedChangeLanguage() {
            const hasSensitiveData = localStorage.getItem('hasSensitiveData')
            if (hasSensitiveData == 'true') {
                this.$refs.languageChangeConfirmationModal.openModal();
            } else {
                this.changeLanguage()
            }
        },

        changeLanguage() {
            if (this.$i18n.locale == "en") {
                this.loadArabicSite();
            } else {
                this.loadEnglishSite();
            }
            localStorage.setItem("isLangChanged", "true")
            $('.mm-close').click()
            window.location.reload()
        },

        clickedLogout() {
            this.$store.commit('config/setAuthenticated',false);
            this.$store.dispatch('logout');
        },
    },
    watch: {
        cartItemCount(data) {
            this.cartItemCount = data;
        },
        siteContentResponse(data) {
            localStorage.setItem("siteContentData", JSON.stringify(data))
            this.$root.setSiteContentLoaded(true);
        }
    },
    mounted() {
        window.headerComponent = this;
        this.$i18n.locale = this.currentLang;
        if (this.$i18n.locale == "ar") {
            this.updateLanguageClassInBody("ar");
            require("@/assets/fonts/GE_Flow/ge-flow.css");
            this.appedRTLStyleSheetToHead();
        } else {
            this.updateLanguageClassInBody("en");
            const element = document.getElementById("rtlCssFile");
            if (element) element.remove();
        }
        this.updateCartItemCount();
        window.localeLang = this.$i18n.locale;

        window.addEventListener('load', () => {
            this.showCartCount = true
            // document.getElementsByClassName('material-icons').inn.add("mat-icon-font-missing");
        })

        const siteContentData = JSON.parse(localStorage.getItem("siteContentData"));
        if (localStorage.getItem("siteContentData" == null) || siteContentData == null) this.$store.dispatch('getHomeScreenData')
    }   
}
</script>


<style>
	.rtl {
		direction: rtl;
		text-align: right;
	}
	.ltr {
		direction: ltr;
		text-align: left;
	}
    .lang-container{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 30px;
        border: 2px solid black;
        border-radius: 25px;
        cursor: pointer;
        margin: 0px;
    }
    .lang-container span{
        margin: 0px 5px;
    }
    .lang-container:hover{
        background-color: whitesmoke;
    }
</style>
