<template>
    <div>
        <div id="languageChangeConfirmationModal" ref="modal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-sm">
                <div class="modal-content border border-2 border-color-primary">
                    <div class="modal-body px-3 px-md-5">
                        <div class="flex justify-content-center">
                            <img src="@/assets/favicon/logo.png" alt="Platinum Fit" width="70px" style="width: 70px" />
                        </div>
                        <hr class="mt-3 custom-hr" color="#f36122"/>
                        <div>
                            <p class="font-18 font-weight-bold text-primary mb-3">{{ $t("header.confirmLanguageChange") }}</p>
                            <p class="font-16">{{ $t("header.youWillLoseAllYourUnsavedWork") }}</p>
                            <p class="font-16 mt-0 mt-md-2">{{ $t("header.doYouWantToContinue") }}</p>

                        </div>
                        <div class="d-flex flex-row justify-content-between m-0 my-4 span-2">
                            <div>
                                <button @click="closeModal(true)" type="button" class="btn btn-default btn-round bg-light-gray mb-2 px-4">{{ $t("header.cancel") }}</button>
                            </div>
                            <div>
                                <button @click="confirmClicked()" type="button" class="btn btn-primary btn-round px-4">{{ $t("header.confirm") }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "LanguageChangeConfirmationModal",
    methods: {
        openModal() {
            window.jQuery("#languageChangeConfirmationModal").modal("show");
        },
        closeModal() {
            window.jQuery("#languageChangeConfirmationModal").modal("hide");
        },
        confirmClicked() {
            this.$emit('confirm')
        }
    }
}
</script>