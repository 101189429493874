const state = {
  currentLang: localStorage.getItem("currentLang") ??  "en",
  isAuthenticated: localStorage.getItem('isAuthenticated') === 'true'
}

const mutations = {
  setCurrentLang(mutationState, payload) {
    mutationState.currentLang = payload;
  },
  setAuthenticated(state, payload) {
    state.isAuthenticated = payload;
    localStorage.setItem('isAuthenticated', payload); // sill store 'true' in local storage
  }
};

export default {
  namespaced: true,
  state,
  mutations
};
