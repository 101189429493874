import axios from '../../axios'
import NProgress from "nprogress"

export default {
    state: {
        orderConfirmationResponse: [],
        createSubscriptionResponse: [],
        updateSubscriptionResponse: []
    },
    getters: {
        orderConfirmationResponse: state => state.orderConfirmationResponse,
        createSubscriptionResponse: state => state.createSubscriptionResponse,
        updateSubscriptionResponse: state => state.updateSubscriptionResponse
    },
    mutations: {
        SET_ORDER_CONFIRMATION_RESPONSE(state, data) {
            state.orderConfirmationResponse = data;
        },
        SET_CREATE_SUBSCRIPTION_RESPONSE(state, data) {
            state.createSubscriptionResponse = data;
        },
        SET_UPDATE_SUBSCRIPTION_RESPONSE(state, data) {
            state.updateSubscriptionResponse = data;
        }
    },
    actions: {
        async getOrderConfirmationDetails({commit}, id) {
            NProgress.start();
            await axios.get(`subscriptions/${id}`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        this.dispatch('updateCartItemCount', res.data?.response?.cart);
                        commit("SET_ORDER_CONFIRMATION_RESPONSE", res.data);
                    } else {
                        commit("SET_ORDER_CONFIRMATION_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_ORDER_CONFIRMATION_RESPONSE", error.response.data);
                }
            );
        },

        async createSubscription({commit}, data) {
            NProgress.start();
            await axios.post("subscriptions", data).then((response) => {
                NProgress.done();
                commit("SET_CREATE_SUBSCRIPTION_RESPONSE", response.data);
            },
                (error) => {
                    NProgress.done();
                    commit("SET_CREATE_SUBSCRIPTION_RESPONSE", error.response.data);
                }
            );
        },

        async updateSubscriptionStatus({commit}, data) {
            NProgress.start();
            await axios.put(`subscriptions`, {
                paid_amount: data.paid_amount,
                payment_id: data.payment_id,
                paid_on: data.paid_on,
                payment_type_id: data.payment_type_id,
                payment_status: data.payment_status,
                payment_result_code: data.payment_result_code,
                payment_token: data.payment_token,
                order_reference_number: data.order_reference_number,
                administrative_charge: data.administrative_charge,
                subscription_id: data.subscription_id
            }).then((response) => {
                NProgress.done();
                if(response.data.code == 401) {
                    this.dispatch('logout');
                } else {
                    commit("SET_UPDATE_SUBSCRIPTION_RESPONSE", response.data);
                }
            },
                (error) => {
                    NProgress.done();
                    if (error.response.status == 401) {
                        this.dispatch('logout');
                    } else {
                        commit("SET_UPDATE_SUBSCRIPTION_RESPONSE", error.response.data);
                    }
                }
            );
        }

    }
}