import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        updateMealSectorValueResponse: []
    },
    getters: {
        updateMealSectorValueResponse: (state) => state.updateMealSectorValueResponse
    },
    mutations: {
        SET_UPDATE_MEAL_SELECTOR_RESPONSE(state, data) {
            state.updateMealSectorValueResponse = data;
        }
    },
    actions: {
        async updateMealSectorValue({commit}, data) {
            NProgress.start();
            await axios.put(`meal-selection`, {
                cart_id: data.cart_id,
                cart_item_id: data.cart_item_id,
                meals_selected_by: data.meals_selected_by
            }).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) {
                        this.dispatch('logout');
                    }else if (res.data.status) {
                        commit("SET_UPDATE_MEAL_SELECTOR_RESPONSE", res.data);
                    } else {
                        commit("SET_UPDATE_MEAL_SELECTOR_RESPONSE", []);
                    }
                },
                (error) => {
                    NProgress.done();
                    commit("SET_UPDATE_MEAL_SELECTOR_RESPONSE", error.response.data);
                }
            );
        },

    }
}