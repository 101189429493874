import axios from 'axios'
import NProgress from "nprogress"
import { Decrypt } from '../../common_services/hesabe-aes-js'

export default {
    state: {
        hesabeDetails: []
    },
    getters: {
        hesabeDetails: state => state.hesabeDetails
    },
    mutations: {
        SET_HESABE_DETAILS(state, data) {
            state.hesabeDetails = data;
        }
    },
    actions: {
        hesabeCheckoutRequest({ commit }, data) {
            NProgress.start();
            let axiosConfig = {
                headers: {
                'Content-Type': 'application/json',
                'accessCode': process.env.VUE_APP_HESABE_ACCESS_CODE
                }
              };
            axios.post(`${process.env.VUE_APP_HESABE_BASE_URL}checkout`, data, axiosConfig)
            
              .then(function (response) {
                    NProgress.done();
                    commit("SET_HESABE_DETAILS", JSON.parse(Decrypt(response.data)));
              })
              .catch(function (error) {
                    NProgress.done();
                    commit("SET_HESABE_DETAILS", error.response.data);
              });
        }
    }
}