import axios from '../../axios'
import NProgress from "nprogress";

export default {
    state: {
        bookingFormResponse: {},
        timeSlotResponse: {},
        createBookingResponse: {},
        updateBookingResponse: {},
        bookingsResponse: {},
        bookingResponse: {}
    },
    getters: {
        bookingFormResponse: (state) => state.bookingFormResponse,
        timeSlotResponse: (state) => state.timeSlotResponse,
        createBookingResponse: (state) => state.createBookingResponse,
        updateBookingResponse: (state) => state.updateBookingResponse,
        bookingsResponse: (state) => state.bookingsResponse,
        bookingResponse: (state) => state.bookingResponse,
    },
    mutations: {
        SET_BOOKING_FORM_RESPONSE(state, data) {
            state.bookingFormResponse = data;
        },
        SET_TIME_SLOT_RESPONSE(state, data) {
            state.timeSlotResponse = data;
        },
        SET_BOOKING_CREATION_RESPONSE(state, data) {
            state.createBookingResponse = data;
        },
        SET_UPDATE_BOOKING_RESPONSE(state, data) {
            state.updateBookingResponse = data;
        },
        SET_BOOKINGS_RESPONSE(state, data) {
            state.bookingsResponse = data;
        },
        SET_BOOKING_RESPONSE(state, data) {
            state.bookingResponse = data;
        }
    },
    actions: {

        async getBookingFormData({commit}) {
            NProgress.start();
            await axios.get(`appointment-bookings-form`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) this.dispatch('logout');
                    commit("SET_BOOKING_FORM_RESPONSE", res.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_BOOKING_FORM_RESPONSE", error.response);
                }
            );
        },

        async getTimeSlotsForDay({commit}, params) {
            NProgress.start();
            await axios.get(`appointment-bookings-time-slots?user_id=${params.dietian_id}&date=${params.date}`).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) this.dispatch('logout');
                    commit("SET_TIME_SLOT_RESPONSE", res.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_TIME_SLOT_RESPONSE", error.response);
                }
            );
        },

        async createAppointmentBooking({commit}, data) {
            NProgress.start();
            await axios.post(`appointment-booking`, data).then(
                (res) => {
                    NProgress.done();
                    if(res.data.code == 401) this.dispatch('logout');
                    commit("SET_BOOKING_CREATION_RESPONSE", res.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_BOOKING_CREATION_RESPONSE", error.response);
                }
            );
        },

        async updateAppointmentBooking({commit}, data) {
            NProgress.start();
            await axios.put(`appointment-booking`, data).then(
                (response) => {
                    NProgress.done();
                    if(response.data.code == 401) return this.dispatch('logout');
                    commit("SET_UPDATE_BOOKING_RESPONSE", response.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_UPDATE_BOOKING_RESPONSE", error.response);
                }
            );
        },

        async getAppointmentBookings({commit},) {
            NProgress.start();
            await axios.get(`customer-booking`).then(
                (response) => {
                    NProgress.done();
                    if(response.data.code == 401) return this.dispatch('logout');
                    commit("SET_BOOKINGS_RESPONSE", response.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_BOOKINGS_RESPONSE", error.response);
                }
            );
        },

        async getAppointmentBooking({commit}, id) {
            NProgress.start();
            await axios.get(`appointment-booking/${id}`).then(
                (response) => {
                    NProgress.done();
                    if(response.data.code == 401) return this.dispatch('logout');
                    commit("SET_BOOKING_RESPONSE", response.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_BOOKING_RESPONSE", error.response);
                }
            );
        },

        async cancelDietitianAppointment({commit}, data) {
            NProgress.start();
            await axios.put(`cancel-booking/${data.id}`,{
                status: 'cancelled',
                rejected_reason: data.rejected_reason
            }).then(
                (response) => {
                    NProgress.done();
                    if(response.data.code == 401) return this.dispatch('logout');
                    commit("SET_BOOKINGS_RESPONSE", response.data);
                },
                (error) => {
                    NProgress.done();
                    commit("SET_BOOKINGS_RESPONSE", error.response);
                }
            );
        }

    }
}