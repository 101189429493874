import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/css/style.css";
import "./assets/css/custom.css";
import i18n from "@/i18n";
import 'sweetalert2/dist/sweetalert2.min.css';
import VueSweetalert2 from "vue-sweetalert2";
import Toaster from '@meforma/vue-toaster';

window.$ = window.jQuery = require("jquery");

createApp(App)
.use(require("moment"))
.use(store)
.use(Toaster, {position: 'top-right'})
.use(router)
.use(VueSweetalert2)
.use(i18n)
.mount("#app");
