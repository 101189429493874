import { createStore } from "vuex";
import VuexPersist from "vuex-persist";
import config from "./modules/config";
import home from "./modules/home";
import auth from "./modules/auth";
import contactUs from "./modules/contact-us";
import profile from "./modules/profile";
import ProductDetails from "./modules/product-details";
import checkout from "./modules/checkout";
import subscription from "./modules/subscription";
import cart from "./modules/cart";
import hesabeCheckout from "./modules/hesabe-checkout";
import mealPlan from "./modules/meal-plan";
import dietitian from "./modules/dietitian";

const vuexPersist = new VuexPersist({
  key: "platinum-fit-web",
  storage: localStorage,
});

export default createStore({
  plugins: [vuexPersist.plugin],
  state: {},
  mutations: {},
  actions: {},
  modules: {
    config,
    home,
    auth,
    contactUs,
    profile,
    ProductDetails,
    checkout,
    subscription,
    cart,
    hesabeCheckout,
    mealPlan,
    dietitian
  },
});
