import hesabeCrypt from "hesabe-crypt";
import aesjs from "aes-js";

let key = aesjs.utils.utf8.toBytes(process.env.VUE_APP_HESABE_SECRET_KEY);
let iv = aesjs.utils.utf8.toBytes(process.env.VUE_APP_HESABE_IV_KEY);

let aesCrypt = new hesabeCrypt(key, iv);

/**
 * Encrypt the given JSON object using KEY & IV
 * and returns the encrypted string
 * @param obj JSON OBJEXT
 * @returns encrtpted json object in string format
 */
export function Encrypt(obj) {
    return aesCrypt.encryptAes(JSON.stringify(obj));
}

/**
 * Decrypt the encrypted string using KEY & IV 
 * and returns the decrypted the string
 * @param encryptedString 
 * @returns Decrypted data
 */
export function Decrypt(encryptedString) {
    return aesCrypt.decryptAes(encryptedString);
}