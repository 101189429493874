import { createRouter, createWebHistory } from "vue-router";
import * as sessionManager from '../common_services/session-manager.js';

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about-us",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/cart",
    name: "Cart",
    component: () => import("../views/cart/Cart.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/checkout",
    name: "Checkout",
    component: () => import("../views/checkout/Checkout.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/payment",
    component: () => import("../views/checkout/Payment.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/thank-you/:id",
    name: "CheckoutConfirmation",
    component: () => import("../views/checkout/CheckoutConfirmation.vue"),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("../views/ContactUs.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/auth/Login.vue"),
    meta: {
      guestSession: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../views/auth/ForgotPassword.vue"),
    meta: {
      guestSession: true,
    },
  },
  {
    path: "/order-details/:id",
    name: "OrderDetails",
    props: true,
    component: () => import("../views/OrderDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/order-meal-details/:id",
    name: "OrderMealDetails",
    props: true,
    component: () => import("../views/OrderMealDetails.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/orders",
    name: "Orders",
    component: () => import("../views/Orders.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/package-categories/:id",
    name: "PackageCategory",
    props: true,
    component: () => import("../views/package-details/ProductDetails.vue"),
  },
  {
    path: "/dislikes-allergies/:cartItemId",
    name: "DislikesAllergies",
    props: true,
    component: () => import("../views/package-details/DislikesAllergies.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/meals/:cartItemId",
    name: "Meals",
    props: true,
    component: () => import("../views/package-details/Meals.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/profile/Profile.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("../views/auth/Register.vue"),
    meta: {
      guestSession: true,
    },
  },
  {
    path: "/order-failed",
    name: "OrderFailed",
    component: () => import("../views/checkout/CheckoutFailed.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "TermsAndConditions",
    component: () => import("../views/TermsAndConditions.vue"),
  },
  {
    path: "/privacy_policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/reset-password",
    component: () => import("../views/auth/ResetPassword.vue"),
  },
  {
    path: "/quick-sign-up",
    component: () => import("../views/auth/QuickSignup.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../views/error/NotFound.vue"),
  },
  {
    path: "/dietitian-appointment",
    name: "DietitianAppointment",
    component: () => import("../views/dietitian-appointment/index.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/appointments",
    name: "Appointments",
    component: () => import("../views/dietitian-appointment/Appointments.vue"),
  },
  {
    path: "/dietitian-calendar",
    name: "DietitianCalendar",
    component: () => import("../views/dietitian-appointment/calendar.vue"),
  },
  {
    path: "/appointment-preview",
    name: "AppointmentPreview",
    component: () => import("../views/dietitian-appointment/preview.vue"),
  },
  {
    path: "/appointment-confirmed/:id",
    name: "AppointmentConfirmation",
    component: () => import("../views/dietitian-appointment/Confirmation.vue"),
  },
  {
    path: "/booking-payment",
    component: () => import("../views/dietitian-appointment/Payment.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      const el = window.location.href.split("#")[1];
      if (el.length) {
        document.getElementById(el).scrollIntoView({ behavior: "smooth" });
      }
    } else if (savedPosition) {
      return savedPosition;
    } else {
      document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    sessionManager.isLoggedIn() ? next() : next({ path: '/login', query: { redirect: to.fullPath } })
  } else {
    next()
  }
});


export default router;
