<template>
    <footer id="pt-footer" class="bg-darkblue mt-0 pt-3">
        <div class="pt-footer-col tt-color-scheme-01">
            <div class="container">
                <div class="row">
                    <div class="col-md-2 pt-3 d-none d-md-inline">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.info") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li><router-link class="text-white" to="/">{{ $t("footer.home") }}</router-link></li>
                                    <li><router-link class="text-white" to="/about-us">{{ $t("footer.aboutUs") }}</router-link></li>
                                    <li v-show="!isLoggedIn"><router-link class="text-white" to="/login">{{ $t("footer.login") }}</router-link></li>
                                    <li><router-link class="text-white" to="/contact-us">{{ $t("footer.contactUs") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 pt-3">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.ourPolicies") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li><router-link class="text-white" to="/terms-and-conditions">{{ $t("footer.termsAndConditions") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2 pt-3 d-none d-md-inline">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.order") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <ul class="pt-list">
                                    <li v-show="isLoggedIn"><router-link class="text-white" to="/profile">{{ $t("footer.myAccount") }}</router-link></li>
                                    <li><router-link class="text-white" to="/cart">{{ $t("footer.cart") }}</router-link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 pt-3">
                        <div class="">
                            <h4 class="pt-footer-title pt-collapse-title new-color">
                                {{ $t("footer.contactUs") }}
                            </h4>
                            <div class="pt-collapse-content ml-md-0">
                                <address class="pt-address">
                                    <a class="text-white m-0" target="_blank" :href="addressMapLink" v-text="address"></a>
                                    <p class="text-white mt-1"><span v-text="timing"></span></p>
                                    <p class="text-white mt-1">
                                        <svg style="margin-bottom:-4px;" width="22" height="22" :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'" viewBox="0 0 18 18">
                                            <use xlink:href="#icon-services_support"></use>
                                        </svg>
                                        
                                        <a dir="ltr" :href="'tel:'+phoneNumber" target='_blank' class="text-white digit-text" v-text="phoneNumber"></a>
                                        <img src="@/assets/icons/whatsapp.png" :class="$i18n.locale == 'ar' ? 'mr-3 ml-2' : 'ml-3 mr-2'" width="22" height="22" /> 
                                        <a dir="ltr" :href="'https://wa.me/'+whatsappNumber" target='_blank' class="text-white digit-text" v-text="whatsappNumber"></a>
                                    </p>
                                    <p class="text-white mt-1">
                                        <svg style="margin-bottom:-6px;" width="22" height="22" viewBox="0 0 18 18" :class="$i18n.locale == 'ar' ? 'ml-2' : 'mr-2'">
                                            <use xlink:href="#icon-mail"></use>
                                        </svg>  
                                        <a dir="ltr" :href="'mailto:'+emailId" target='_blank' class="text-white digit-text" v-text="emailId"></a>
                                    </p>
                                    <p class="text-white mt-1">{{ $t("footer.followUs") }}:<a target="_blank" class="text-white" :href="instagramLink">
                                        <svg width="22" height="22" :class="$i18n.locale == 'ar' ? 'mr-3' : 'ml-3'" style="margin-bottom:-4px;" viewBox="0 0 18 18">
                                            <use xlink:href="#icon-social_icon_instagram"></use>
                                        </svg>
                                    </a></p>
                                </address>
                            </div>

                        </div>
                    </div>
                    <div class="col-12 col-md-2 d-none d-sm-flex">
                        <router-link class="mx-auto mt-3" to="/">
                            <img class="w-120" src="../assets/favicon/logo_white.png" alt="Platinum Fit" />
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import * as sessionManager from '../common_services/session-manager.js'

export default {
    computed: {
        isLoggedIn() {
            return sessionManager.isLoggedIn();
        },
        address() {            
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_address')?.value
        },
        addressMapLink() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_address_map')?.value
        },
        timing() {          
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }     
            return this.$root.getSiteContentDataForKey('footer_timing')?.value
        },
        phoneNumber() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_phone')?.value
        },
        whatsappNumber() { 
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }              
            return this.$root.getSiteContentDataForKey('footer_whatsapp')?.value
        },
        emailId() {
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }   
            return this.$root.getSiteContentDataForKey('footer_email')?.value
        },
        instagramLink() {         
            if (this.$root.isSiteContentLoaded == true) { /* A check to fetch record from local storage again */ }      
            return this.$root.getSiteContentDataForKey('footer_instagram')?.value
        }
    },
    watch: {
        customerData(data) {
            this.customerData = data;
        }
    },
}
</script>