<template>
    <div id="sidenavMain" class="sidenav" :class="this.$i18n.locale == 'ar' ? 'sidenav-ar' : 'sidenav-en'" @click.self="closeNav()">
        <div id="sidenavContent" class="sidenav-content">
            <a href="javascript:void(0)" class="closebtn font-24 pb-2" :class="this.$i18n.locale == 'ar' ? 'closebtn-ar' : ''" @click="closeNav()"><span class="material-icons-outlined">
close
</span></a>
            <a href="javascript:void(0)" @click="menuClicked('/about-us')" class="mt-3">
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.aboutUs") }}
            </a>

            <a href="javascript:void(0)" @click="menuClicked('/#packages')">
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.packages") }}
            </a>

            <a v-show="isLoggedIn" href="javascript:void(0)" @click="menuClicked('/profile')" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.profile") }}
            </a>

            <a v-show="isLoggedIn" href="javascript:void(0)" @click="menuClicked('/orders')" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.orders") }}
            </a>

            <a v-show="isLoggedIn" href="javascript:void(0)" @click="menuClicked('/appointments')" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.appointments") }}
            </a>

            <a v-show="!isLoggedIn" href="javascript:void(0)" @click="menuClicked('/login')" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.login") }}
            </a>

            <a href="javascript:void(0)" @click="menuClicked('/contact-us')" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.contactUs") }}
            </a>

            <a href="javascript:void(0)" @click="clickedLangChange()" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("header.localeLang") }}
            </a>

            <a href="javascript:void(0)" @click="menuClicked('/dietitian-appointment')" class="mt-2 font-16 text-primary text-underline" >
                <span class="material-icons-outlined font-16 pr-2">arrow_forward_ios</span>
                {{ $t("dietitianAppointment.bookAnAppointment") }}
            </a>
        </div>
    </div>
</template>

<script>

import { mapState } from 'vuex'

export default {
    data() {
        return {
            isMenuOpen: false,
        };
    },
    methods: {
        openNav() {
            this.isMenuOpen = !this.isMenuOpen
        },
        closeNav() {
            this.isMenuOpen = !this.isMenuOpen
        },
        menuClicked(path) {
            this.closeNav()
            this.$router.push(path)
        },
        clickedLangChange() {
            this.closeNav()
            window.headerComponent.clickedChangeLanguage();
        },
    },
    watch: {
        isMenuOpen(val) {
            if (val) {
                document.getElementById("sidenavMain").style.width = "100%";
                document.getElementById("sidenavContent").style.display = "block";
            } else {
                document.getElementById("sidenavMain").style.width = "0";
                document.getElementById("sidenavContent").style.display = "none";
            }
        }
    },
    computed: {
        ...mapState({
            isLoggedIn: (state) => state.config.isAuthenticated,
        })
    },
};
</script>


<style scoped>

.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0,0,0,.55);
  overflow-x: hidden;
  transition: 0.5s;
}

.sidenav-en {
  left: 0;
  right: unset;
}

.sidenav-ar {
  right: 0;
  left: unset;
}

.sidenav a {
  padding: 14px 8px 14px 20px;
  text-decoration: none;
  font-size: 18px;
  color: #353545;
  display: block;
  transition: 0.3s;
  font-weight: 800;
  line-height: 22px;
  letter-spacing: .04em;
}

.sidenav a:hover {
  color: #353545cc;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25%;
  left: unset;
  padding-left: 0;
  padding-right: 0
}

.closebtn-ar {
  left: 25% !important;
  right: unset !important;
}

.sidenav-content {
    width: 80%;
    height: 100%;
    padding-top: 1rem;
    background-color: #fff;
    overflow-y: scroll;
}

</style>
