import axios from 'axios'
import config from '../store/modules/config'

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_ENV == "production" ? 'https://api.platinumfitkw.com/api/customers/' : process.env.VUE_APP_ENV == "staging" ? 'https://api-staging.platinumfitkw.com/api/customers/' : 'http://127.0.0.1:8000/api/customers/',
    headers: {
        'locale': config.state.currentLang,
        'Apikey': '',
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
})

apiClient.interceptors.request.use(
    config => {
        const token = JSON.parse(localStorage.getItem("customerInfo"))?.token;
        if (token) config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    },
    error => {
        return Promise.reject(error)
});

export default apiClient;